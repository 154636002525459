// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-album-jsx": () => import("./../../../src/pages/album.jsx" /* webpackChunkName: "component---src-pages-album-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-media-jsx": () => import("./../../../src/pages/media.jsx" /* webpackChunkName: "component---src-pages-media-jsx" */),
  "component---src-templates-album-jsx": () => import("./../../../src/templates/album.jsx" /* webpackChunkName: "component---src-templates-album-jsx" */),
  "component---src-templates-media-jsx": () => import("./../../../src/templates/media.jsx" /* webpackChunkName: "component---src-templates-media-jsx" */)
}

